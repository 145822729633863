const menuItems = {
    Starters: [
        { name: 'Guacamole & Chips', price: '11.95', description: 'Fresh avocado, onions, cilantro, jalapenos and lime juice'},
        { name: 'Hacienda Appetizer', price: '19.95', description: 'Taquitos rancheros, 2 chimichanguitas, Small Quesadilla & choriqueso'},
        { name: 'Quesadilla Suiza', price: '11.50', description: 'Flour Quesadilla with jack cheese, topped with guacamole and sour cream, pico de gallo, meat of choice +$4.00'},
        { name: 'Taquitos Rancheros', price: '12.50', description: 'shredded chicken or shredded beef taquitos, served with queso fresco, guacamole, sour cream, lettuce and mild salsa'},
        { name: 'Nachos', price: '11.95', description: 'crispy corn tortillas topped with refried beans, jack cheese, and pico de gallo'},
        { name: 'Super Nachos', price: '14.95', description: 'crispy corn tortillas, topped with refried beans, jack cheese, sour cream, pico de gallo, guacamole, and choice of meat.'},
    ],
    Soups: [
        { name: 'Tortilla Soup', price: '14.95', description: 'chicken, tortilla strips, jack cheese, and fresh avocado, onions, cilantro, and sour cream'},
        { name: 'Caldo 7 Mares', price: '$22.95', description: 'seafood soup, served with corn or flour tortillas'},
        { name: 'Caldo de Res', price: '$14.95', description: 'beef soup with vegetables, served with corn or flour tortillas' },
        { name: 'Caldo de Pollo', price: '$14.95', description: 'chicken soup with vegetables, served with corn or flour tortillas'},
        { name: 'Caldo de Albondigas', price: '$15.95', description: 'meatball soup with vegetables, served with cord or flour tortillas'},
    ],
    Tacos: [
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Tinga | Al Pastor | Carnitas | Chile Verde | Veggies '},
        { name: '1 Taco', price: '$4.25', description: 'choice of meat, with cilantro, onions, and salsa. If cripsy taco served with lettuce, tomatoes, cheese, and mild salsa.'},
        { name: 'Fish Taco', price: '$4.95', description: 'grilled basa fish with chipotle sauce, pico de gallo, and lettuce'},
        { name: 'Breaded Fish Taco', price: '$5.25', description: 'breaded basa fish with chipotle sauce, pico de gallo, and lettuce'},
        { name: 'Shrimp Taco', price: '$5.25', description: 'with chipotle sauce, pico de gallo, and lettuce'},
        { name: 'Taco de Lengua', price: '$5.25', description: 'cilantro, onions, green salsa'},
        { name: 'Lettuce Wrapped Taco', price: '$5.25', description: 'choice of meat, cilantro, onions, green salsa'},
    ],
    Burritos: [
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Tinga | Al Pastor | Carnitas | Chile Verde | Veggies '},
        { name: 'Burrito', price: '$9.95', description: 'rice, bean, and cheese'},
        { name: 'Regular Burrito', price: '$12.25', description: 'choice of meat, whole beans, rice, topped with salsa'},
        { name: 'Super Burrito', price: '$13.50', description: 'choice of meat, whole beans, rice, jack cheese, sour cream and guacamole. Topped with salsa'},
        { name: 'Super Burrito Bowl', price: '$13.25', description: 'choice of meat, whole beans, rice, jack cheese, topped with guacamole, sour cream, and salsa'},
        { name: 'Macho Burrito', price: '$15.95', description: 'choice of meat, refried beans, rice, jack cheese, topped with guacamole, sour cream, cilantro, tomatoes, onions, and salsa'},
        { name: 'Vegetariano Burrito', price: '$12.25', description: 'wheat tortilla, rice, beans, cheese, lettuce, guacamole, sour cream and tomatoes. Topped with salsa'},
        { name: 'Veggie Burrito', price: '$11.95', description: 'spinach tortilla, steamed veggies, rice, black beans, lettuce, topped with salsa'},
        { name: 'Shrimp Burrito', price: '$17.50', description: 'prawns sauteed with bell peppers, onions, mushrooms, rice,beans, sour cream, and guacamole, topped with salsa'},
        { name: 'California Burrito', price: '$13.50', description: 'choice of meat, french fries, jack cheese, guacamole,and pico de gallo'},
        { name: 'Mole Burrito', price: '$14.50', description: 'choice of meat, mole, whole beans, rice, jack cheese, sour cream, guacamole, topped with mole salsa'},
    ],
    Combinations: [
        { name: '', price: '', description: 'The following plates are served with rice,  beans (whole, black or refried)'},
        { name: 'One Item', price: '$14.25', description: ''},
        { name: 'Two Items', price: '$17.85', description: ''},
        { name: 'Three Items', price: '$20.95', description: ''},
        { name: 'Item Options', price: '', description: 'Taco | Enchilada | Tostada | Sope | Chile Relleno | Tamal'},
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Tinga | Al Pastor | Carnitas | Chile Verde | Veggies '},
    ],
    Side_Orders: [
        { name: 'Beans', price: '$4.95', description: ''},
        { name: 'Rice', price: '$4.95', description: ''},
        { name: 'Rice and Beans', price: '$8.50', description: ''},
        { name: 'Tamal', price: '$6.95', description: ''},
        { name: 'Chile Relleno', price: '$7.95', description: ''},
        { name: 'Tortillas', price: '$2.50', description: 'corn or flour'},
        { name: 'Enchilada', price: '$6.50', description: ''},
        { name: 'Sour Cream', price: '$2.50', description: ''},
        { name: 'Cheese', price: '$1.50', description: ''},
        { name: 'Salsa', price: '$1.50', description: ''},
    ],
    Fajitas: [
        { name: '', price: '', description: 'sizzling fajita plate with rice, beans (pinto, refried, black beans) with corn or flour tortillas and lettuce guacamole and tomatoes'},
        { name: 'Veggie Fajitas', price: '$19.95', description: ''},
        { name: 'Chicken Fajitas', price: '$21.95', description: ''},
        { name: 'Steak Fajitas', price: '$21.95', description: ''},
        { name: 'Chicken & Steak Fajitas', price: '$22.95', description: ''},
        { name: 'Pork Fajitas', price: '$20.95', description: ''},
        { name: 'Fish Fajitas', price: '$23.95', description: ''},
        { name: 'Prawn Fajitas', price: '$24.95', description: ''},
        { name: 'Mixed Fajitas', price: '$27.95', description: ''},
    ],
    Specialties: [
        { name: '', price: '', description: 'The following plates are served with rice,  beans (whole, black or refried), corn or flour tortillas'},
        { name: 'Molcajete', price: '$33.50', description: 'chicken, beef, chorizo, and prawns with bell peppers, onions, in a spicy sauce, cactus, and queso fresco'},
        { name: 'Carne Asada', price: '$19.95', description: 'tender  grilled steak served with green onions, tomatoes, and avocado'},
        { name: 'Pollo a la Plancha', price: '$18.50', description: 'grilled chicken breast served with green onions, tomatoes, and avocado'},
        { name: 'Enchiladas Suizas', price: '$18.95', description: 'two enchiladas topped with green tomatillo sauce, jack cheese, and sour cream'},
        { name: 'Pollo con Crema', price: '$21.00', description: 'chicken in a creamy sauce, with mushrooms'},
        { name: 'Pollo en Mole', price: '$21.00', description: 'chicken in a mole sauce, topped with sesame seeds'},
        { name: 'Camarones con Steak', price: '$24.95', description: 'Steak and prawns'},
    ],
    Seafood: [
        { name: '', price: '', description: 'The following plates are served with rice,  beans (whole, black or refried), corn or flour tortillas'},
        { name: 'Mojarra Frita', price: '$22.95', description: 'Whole fried tilapia fish'},
        { name: 'Filete de Pescado', price: '$21.95', description: 'basa fish filet'},
        { name: 'Filete Empanizado', price: '$22.95', description: 'breaded basa fish filet'},
        { name: 'Camarones a la Hacienda', price: '$22.95', description: 'prawns sautéed w/ bell peppers, onions, and mushrooms'},
        { name: 'Camarones a la Diabla', price: '$22.95', description: 'extra hot and spicy prawns'},
        { name: 'Camarones con Crema', price: '$22.95', description: 'prawns sautéed in creamy sauce, with mushrooms'},
        { name: 'Camarones al Mojo de Ajo', price: '$22.95', description: 'sautéed prawns in garlic sauce, with tomatoes and onions'},
        { name: 'Enchiladas de Camaron', price: '$21.95', description: 'sautéed shrimp enchiladas with green salsa.'},
    ],
    Tostadas_y_Cocteles: [
        { name: 'Tostada de Ceviche', price: '$8.50', description: 'lime marinated basa fish mixed with pico de gallo, avocado served on a crispy corn tostada'},
        { name: 'Tostada de Camaron', price: '$8.95', description: 'grilled shrimp mixed with pico de gallo, avocado served on a cripsy corn tostada'},
        { name: 'Coctel de Camaron', price: '$20.95', description: 'prawns cocktail served with ketchup, clamato juice, avocado, and pico de gallo'},
        { name: 'Campechana', price: '$20.95', description: 'shrimp and octopus cocktail served with ketchup, clamato juice, avocado, and pico de gallo'}
    ],
    Tortas: [
        { name: 'Torta Regular', price: '$13.50', description: 'beans, cheese, meat, lettuce, avocado, jalapeños, tomatoes, sour cream, and side of fries'},
        { name: 'Torta Cubana', price: '$15.95', description: 'beans, beef, chorizo, hot dog franks, ham, bacon, cheese, eggs, avocado, tomatoes, jalapeños, sour cream and side of fries'},
        { name: 'Torta Milanesa', price: '$14.95', description: 'breaded chicken or steak, beans, cheese, lettuce, avocado, jalapeños, tomatoes, sour cream with side of fries'},
        { name: 'Torta Hawaiana', price: '$14.95', description: 'beans, ham, pineapple, eggs, cheese, tomatoes, avocado, and jalapeños'}
    ],
    Desayuno: [
        { name: 'Breakfast Deluxe Burrito', price: '$12.95', description: 'eggs, rice, beans, cheese, bell peppers, onions, mushrooms, bacon, topped with guacamole, pico de gallo, sour cream, and salsa'},
        { name: 'Morning Burrito', price: '$10.50', description: 'eggs, rice, beans and cheese'},
        { name: 'Huevos Rancheros', price: '$13.95', description: 'fried eggs served over corn tortillas topped with red salsa, served with rice and beans'},
        { name: 'Huevos con Chorizo', price: '$13.95', description: 'served with rice, beans, corn or flour tortillas, lettuce and pico de gallo.'},
        { name: 'Steak & Eggs', price: '$16.95', description: 'served with rice, beans, and corn or flour tortillas'},
        { name: 'Chilaquiles', price: '$14.95', description: 'eggs scrambled with corn tortillas in green or red sauce, queso fresco, onions, cilantro, sour cream, and lettuce'},
        { name: 'Huevos a la Mexicana', price: '$13.95', description: 'egg scramble with onions, tomatoes, jalapeños, served with rice, beans, and corn or flour tortillas'},
        { name: 'Huevos Divorciados', price: '$13.95', description: 'Fried eggs served over corn tortillas, topped with red and green salsa, queso freso, served with beans.'},
    ],
    Kids_Menu: [
        { name: 'Kids Combination', price: '7.50', description: 'Choose an Enchilada | Taco | Sope | Quesadilla, choice of meat, served with rice and beans '},
        { name: 'Kids Burrito', price: '$6.00', description: 'rice, beans, cheese'},
        { name: 'Marcos Burrito', price: '$8.50', description: 'choice of meat, rice, beans, cheese, and sour cream'},
        { name: 'Kids Nachos', price: '$5.95', description: 'corn tortilla chips topped beans and melted jack cheese'},
        { name: 'Kids Super Nachos', price: '$8.50', description: 'corn tortilla chips topped with choice of meat, beans, melted jack cheese, sour cream and guacamole'},
        { name: 'Taquitos Rancheros', price: '$7.50', description: 'choice of shredded chicken or shredded beef, topped with queso fresco, guacamole, sour cream, lettuce, and mild sauce.'},
        { name: 'Chicken Soup', price: '$7.00', description: ''},
        { name: 'Side of Rice and Beans', price: '$5.50', description: ''},
        { name: 'Rice', price: '$4.00', description: ''},
        { name: 'Beans', price: '$4.00', description: ''},
        { name: 'Kids Drink', price: '$3.00', description: ''},
    ],
    Dessert: [
        { name: 'Flan', price: '$6.95', description: ''},
        { name: 'Churros', price: '$7.95', description: ''},
        { name: 'Sopapillas', price: '$7.95', description: ''},
        { name: 'Fried Ice Cream', price: '$7.95', description: ''},
    ],
    Drinks: [
        { name: 'Soft Drinks', price: '$4.95', description: 'Coke | Sprite | Diet Coke | Pibb Ex | Root Beer | Orange Fanta | Lemonade | Iced Tea'},
        { name: 'Mexican Sodas', price: '$4.95', description: 'Mexican Coke | Sangria Senorial | Lime Jarritos | Mandarin Jarritos | Strawberry Jarritos | Tamarindo Jarritos | Mineral Water Jarritos '},
        { name: 'Aguas Frescas', price: '$4.95', description: 'Horchata | Jamaica | Tamarindo'},
        { name: 'Coffee', price: '$4.95', description: ''},
        { name: 'Orange Juice', price: '$6.50', description: ''},
    ],
    Beer: [
        { name: 'Bottled Beer', price: '$6.50', description: 'Corona | Pacifico | Modelo Especial | Negra Modelo | Bohemia | Victoria | XX Amber | XX Lager | Heineken | Heineken 0.0'},
        { name: 'Draft Beer', price: '$7.00', description: 'Lagunitas IPA | Lagunitas Lil Sumpin | Negra Modelo | Modelo Especial | XX Amber | Pacifico | Coors Light | Corona'},
        { name: 'Make it a Michelada', price: '+ $3.00', description: 'Selection of beer + spicy Clamato Mix'},
        { name: 'Make it a Chelada', price: '+ $2.00', description: 'Selection of beer + lime juice and salt'},
    ],
}

export default menuItems